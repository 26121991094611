import { getCurrentUrl } from "@/utils/getCurrentUrl";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SessionProvider, useSession } from "next-auth/react";
import { DefaultSeo, NextSeo } from "next-seo";
import { AppProps } from "next/app";
import { Inter } from "next/font/google";
import Head from "next/head";
import Script from "next/script";
import * as React from "react";
import "./global.css";

const inter = Inter({
  display: "swap",
  weight: ["800", "700", "500", "400"],
  subsets: ["cyrillic", "latin"],
});

/** Список папок, требующие авторизацию и роль админа */
const RESTRICTED_PATHS = ["/admin"];

export default function App({
  Component,
  pageProps: { session, ...pageProps },
  router: { route, asPath },
}: AppProps) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  const canonicalUrl = (getCurrentUrl() + (asPath === "/" ? "" : asPath)).split(
    "?"
  )[0];

  const requireAuth = RESTRICTED_PATHS.some((path) => route.startsWith(path));

  return (
    <SessionProvider session={session}>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <meta name="color-scheme" content={colorScheme} /> */}
      </Head>
      <DefaultSeo
        canonical={canonicalUrl}
        openGraph={{
          type: "website",
          locale: "ru_RU",
          images: [
            {
              url: `${getCurrentUrl()}/logo.png`,
              alt: "Логотип блога",
              height: 141,
              width: 141,
            },
          ],

          url: getCurrentUrl(),
          siteName: "Wofm Блог",
        }}
      />
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          defaultColorScheme="auto"
          theme={{
            colors: {
              description: [
                "#6d6d6d",
                "#6d6d6d",
                "#6d6d6d",
                "#6d6d6d",
                "#6d6d6d",
                "#6d6d6d",
                "#6d6d6d",
                "#6d6d6d",
                "#6d6d6d",
                "#6d6d6d",
              ],
            },
            headings: {
              sizes: {
                h1: { fontSize: "76px", fontWeight: "800" },
                h2: { fontSize: "32px", fontWeight: "700" },
                h3: { fontSize: "24px", fontWeight: "400" },
                h4: { fontSize: "18px", fontWeight: "500" },
              },
            },
            fontFamily: inter.style.fontFamily,
          }}
        >
          <Notifications />
          <ModalsProvider>
            <style jsx global>{`
              html {
                font-family: ${inter.style.fontFamily};
              }
            `}</style>
            {requireAuth ? (
              <Auth>
                <NextSeo nofollow={true} />
                <Component {...pageProps} />
              </Auth>
            ) : (
              <>
                {process.env.NODE_ENV === "production" && (
                  <Script id="yandex-metrika">{`(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)}; m[i].l=1*new Date(); for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }} k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)}) (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym"); ym(94677174, "init", { clickmap:true, trackLinks:true, accurateTrackBounce:true });`}</Script>
                )}

                {process.env.NODE_ENV === "production" && (
                  <>
                    <Script
                      strategy="lazyOnload"
                      src="https://www.googletagmanager.com/gtag/js?id=G-75LFZ82H60"
                    />
                    <Script strategy="lazyOnload" id="google-analytics">
                      {`window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);} gtag('js', new Date());  gtag('config', 'G-75LFZ82H60');`}
                    </Script>
                  </>
                )}

                <Component {...pageProps} />
              </>
            )}
          </ModalsProvider>
        </MantineProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}

function Auth({ children }: React.PropsWithChildren) {
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession({ required: true });

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  return children;
}
